import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Lightbox from 'react-image-lightbox';
import { Trans } from "react-i18next";
import './Gallery.css';

const images = [
  'https://res.cloudinary.com/xcytek/image/upload/v1608589679/Hotel%20Villafontana%20Inn/IMG_1591_t68jpf.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660340/Hotel%20Villafontana%20Inn/b863180b-72a8-480e-9ba3-a8226b8288fb_vcbywt.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660457/Hotel%20Villafontana%20Inn/DSCN8399_ktq4wt.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660337/Hotel%20Villafontana%20Inn/b912c401-1d74-4a9e-bade-55f7da44ee72_jc56xg.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660305/Hotel%20Villafontana%20Inn/01023c74-11e5-4a40-858b-cc4603e37f97_nzqrwa.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660344/Hotel%20Villafontana%20Inn/ba49b1ab-05c3-4b77-973e-4a30452beca7_pyyzlq.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608576470/Hotel%20Villafontana%20Inn/pool_jxn22q.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608589380/Hotel%20Villafontana%20Inn/HVF_FOTOS_ENERO_2016_016_xu9wuh.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608589120/Hotel%20Villafontana%20Inn/HVF_FOTOS_ENERO_2016_011_kudpzg.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660262/Hotel%20Villafontana%20Inn/2a6f28f1-bc82-45f9-8cad-9e757e7fb641_bnz6ln.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660550/Hotel%20Villafontana%20Inn/Double01_jeqzia.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660608/Hotel%20Villafontana%20Inn/15c3705c-471a-4731-ab4e-6dbc2cbcc46f_znor0o.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660273/Hotel%20Villafontana%20Inn/5e0837b1-043c-4c0a-bd21-aaea55df0891_oq8g8g.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660345/Hotel%20Villafontana%20Inn/bff82020-adb5-405a-bf1d-d5ade91f2675_apkgp0.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660257/Hotel%20Villafontana%20Inn/0ca735fd-d43c-4cda-8f71-ca37b99ccd20_acen5h.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660303/Hotel%20Villafontana%20Inn/927e77f0-9fc8-4ace-9569-db2fd5333270_ewyrd5.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660292/Hotel%20Villafontana%20Inn/84d6f6a2-6270-44d8-a233-ded302323f04_t7l3rq.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660311/Hotel%20Villafontana%20Inn/18354bfd-d60c-498b-bf6c-0591cff6e13c_pm6zlz.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660306/Hotel%20Villafontana%20Inn/1602d6bf-5e0a-4d3a-88b4-fdb755ae183a_cscrxo.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660264/Hotel%20Villafontana%20Inn/3b3b0527-d6c5-4f3e-b849-e44ef77c9e9f_zfdgl9.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608588659/Hotel%20Villafontana%20Inn/IMG_0614_dwvlsb.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660608/Hotel%20Villafontana%20Inn/17596581-12fe-406f-93e1-be8b0435a791_vckghu.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660605/Hotel%20Villafontana%20Inn/b584b515-bf5c-4e99-9b7c-0036d2d8be55_ppqk4k.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660351/Hotel%20Villafontana%20Inn/b7470f28-69bb-4af4-b6bf-b0b9250cc3f5_k6vahz.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660320/Hotel%20Villafontana%20Inn/45122b8c-e524-41e5-93e1-4654735a8f1d_ooygfm.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660273/Hotel%20Villafontana%20Inn/5b90685b-3886-4b2d-a1f8-bd001431ac72_ylbkqo.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660320/Hotel%20Villafontana%20Inn/28669e8c-bb74-43c6-a903-da238fc4ca70_g0l600.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660606/Hotel%20Villafontana%20Inn/2134ad37-cab7-41ce-a172-cb948212bb58_myqeph.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660610/Hotel%20Villafontana%20Inn/14e4170b-c564-4b3a-a904-fb06a299a42a_f5pc5b.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660263/Hotel%20Villafontana%20Inn/3a197412-57d0-43d1-8a25-5979b9b84835_cgfwll.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660281/Hotel%20Villafontana%20Inn/9a55debb-86b5-410f-b82c-4f927c51dba7_mkuptp.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608589499/Hotel%20Villafontana%20Inn/HVF_FOTOS_ENERO_2016_039_vzs98a.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608661110/Hotel%20Villafontana%20Inn/DSCN8380_h6ptyi.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1608660297/Hotel%20Villafontana%20Inn/413ec83e-ab63-4c08-a8ca-4a4e34e224b3_b246my.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1618201162/Hotel%20Villafontana%20Inn/gallery/cama-queen_fz52fe.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1618201329/Hotel%20Villafontana%20Inn/gallery/suite-ejecutiva_zemubc.jpg',
  'https://res.cloudinary.com/xcytek/image/upload/v1618201410/Hotel%20Villafontana%20Inn/gallery/suite-ejecutiva-2_ab1nsj.jpg', 'https://res.cloudinary.com/xcytek/image/upload/v1621123484/Hotel%20Villafontana%20Inn/gallery/20210304_125033_fw3yi3.jpg'
];

class Gallery extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    
    render() {
        const { photoIndex, isOpen } = this.state;
        
        return (
    <section className="rh rh-gallery">
        <div className="container">
            <div className="row">
                
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="rh-section-title">
                        <h2><Trans>gallery t</Trans></h2>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 0, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608589679/Hotel%20Villafontana%20Inn/IMG_1591_t68jpf.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 1, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660340/Hotel%20Villafontana%20Inn/b863180b-72a8-480e-9ba3-a8226b8288fb_vcbywt.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 2, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660457/Hotel%20Villafontana%20Inn/DSCN8399_ktq4wt.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 3, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660337/Hotel%20Villafontana%20Inn/b912c401-1d74-4a9e-bade-55f7da44ee72_jc56xg.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 4, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608576524/Hotel%20Villafontana%20Inn/gallery/16_n38xpc.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 5, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660344/Hotel%20Villafontana%20Inn/ba49b1ab-05c3-4b77-973e-4a30452beca7_pyyzlq.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 6, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608576470/Hotel%20Villafontana%20Inn/pool_jxn22q.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 7, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608589380/Hotel%20Villafontana%20Inn/HVF_FOTOS_ENERO_2016_016_xu9wuh.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 8, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608589120/Hotel%20Villafontana%20Inn/HVF_FOTOS_ENERO_2016_011_kudpzg.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 9, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660262/Hotel%20Villafontana%20Inn/2a6f28f1-bc82-45f9-8cad-9e757e7fb641_bnz6ln.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 10, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660606/Hotel%20Villafontana%20Inn/3cb5d3c4-32d0-477a-952e-ace3b1c3e3ee_heppbp.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 11, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660608/Hotel%20Villafontana%20Inn/15c3705c-471a-4731-ab4e-6dbc2cbcc46f_znor0o.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 12, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660273/Hotel%20Villafontana%20Inn/5e0837b1-043c-4c0a-bd21-aaea55df0891_oq8g8g.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 13, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660345/Hotel%20Villafontana%20Inn/bff82020-adb5-405a-bf1d-d5ade91f2675_apkgp0.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 14, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660257/Hotel%20Villafontana%20Inn/0ca735fd-d43c-4cda-8f71-ca37b99ccd20_acen5h.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 15, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660303/Hotel%20Villafontana%20Inn/927e77f0-9fc8-4ace-9569-db2fd5333270_ewyrd5.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 16, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660292/Hotel%20Villafontana%20Inn/84d6f6a2-6270-44d8-a233-ded302323f04_t7l3rq.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 17, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660311/Hotel%20Villafontana%20Inn/18354bfd-d60c-498b-bf6c-0591cff6e13c_pm6zlz.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 18, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660306/Hotel%20Villafontana%20Inn/1602d6bf-5e0a-4d3a-88b4-fdb755ae183a_cscrxo.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 19, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660264/Hotel%20Villafontana%20Inn/3b3b0527-d6c5-4f3e-b849-e44ef77c9e9f_zfdgl9.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 20, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608588659/Hotel%20Villafontana%20Inn/IMG_0614_dwvlsb.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 21, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660608/Hotel%20Villafontana%20Inn/17596581-12fe-406f-93e1-be8b0435a791_vckghu.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 22, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660605/Hotel%20Villafontana%20Inn/b584b515-bf5c-4e99-9b7c-0036d2d8be55_ppqk4k.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 23, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660351/Hotel%20Villafontana%20Inn/b7470f28-69bb-4af4-b6bf-b0b9250cc3f5_k6vahz.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 24, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660320/Hotel%20Villafontana%20Inn/45122b8c-e524-41e5-93e1-4654735a8f1d_ooygfm.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 25, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660273/Hotel%20Villafontana%20Inn/5b90685b-3886-4b2d-a1f8-bd001431ac72_ylbkqo.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 26, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660320/Hotel%20Villafontana%20Inn/28669e8c-bb74-43c6-a903-da238fc4ca70_g0l600.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 27, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660606/Hotel%20Villafontana%20Inn/2134ad37-cab7-41ce-a172-cb948212bb58_myqeph.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 28, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660610/Hotel%20Villafontana%20Inn/14e4170b-c564-4b3a-a904-fb06a299a42a_f5pc5b.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 29, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660263/Hotel%20Villafontana%20Inn/3a197412-57d0-43d1-8a25-5979b9b84835_cgfwll.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 30, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660281/Hotel%20Villafontana%20Inn/9a55debb-86b5-410f-b82c-4f927c51dba7_mkuptp.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 31, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608589499/Hotel%20Villafontana%20Inn/HVF_FOTOS_ENERO_2016_039_vzs98a.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 32, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608661110/Hotel%20Villafontana%20Inn/DSCN8380_h6ptyi.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 33, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660297/Hotel%20Villafontana%20Inn/413ec83e-ab63-4c08-a8ca-4a4e34e224b3_b246my.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 34, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1618201162/Hotel%20Villafontana%20Inn/gallery/cama-queen_fz52fe.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 35, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1618201329/Hotel%20Villafontana%20Inn/gallery/suite-ejecutiva_zemubc.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 36, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1618201410/Hotel%20Villafontana%20Inn/gallery/suite-ejecutiva-2_ab1nsj.jpg" alt="img" />
                        </div>
                    </div>
                </div>
                <div onClick={() => this.setState({ isOpen: true, photoIndex: 37, })} className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="rh-img gallery-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1621123484/Hotel%20Villafontana%20Inn/gallery/20210304_125033_fw3yi3.jpg" alt="img" />
                        </div>
                    </div>
                </div>
               {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
                
            </div>
            

        </div>
    </section>
        );
}
}
export default Gallery;