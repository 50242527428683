import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Trans } from "react-i18next";
import './Header.css';
import logo from '../images/logo.png';

class Header extends Component {

    render() {
        return (

            <div className="nav-header">
                <Navbar collapseOnSelect expand="lg" className="rh navbar navbar-default">
                    <div className="container">
                        <Navbar.Brand href="/index"><img src={logo} alt="logo" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-nav" data-toggle="collapse" data-target="#resposive-nav" aria-expanded="false" />
                        <Navbar.Collapse className="justify-content-end" id="responsive-nav">
                            <ul className="nav navbar-nav navbar-right main-navbar">
                                <li>
                                    <NavLink activeClassName="active" to="/index"><Trans>home</Trans></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="active" to="/rooms"><Trans>rooms</Trans></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="active" to="/gallery"><Trans>gallery</Trans></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink activeClassName="active" to="/places"><Trans>whereto</Trans></NavLink>
                                </li>
                                <li className="nav-item">
                                    <a href="https://reservations.hotelvillafontanainn.com/"><Trans>contact</Trans></a>
                                </li>
                            </ul>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
                {/*<div className="rh-header warning-message-background">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pool-open">*/}
                {/*                <Trans>pool_open</Trans>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}
export default Header;