import React, { Component } from 'react';
import Header from '../Components/Header.jsx';
import ContactInfo from '../Components/ContactInfo.jsx';
import ContactForm from '../Components/ContactForm.jsx';
import Newsletter from '../Components/Newsletter.jsx';
import Footer from '../Components/Footer.jsx';
import { Helmet } from "react-helmet";

class Contact extends Component {
    render() {
        return (
            <div className="contact">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Hotel, Ensenada, hotel en ensenada, ensenada hoteles, ensenada hotel, ensenada hoteles, stay in ensenada, contact, booking, reservation" />
                    <link rel="canonical" href="http://hotelvillafontanainn.com" />
                    <title>Hotel Villa Fontana Inn</title>
                </Helmet>
                <Header />
                <ContactInfo />
                <ContactForm />
                <Newsletter />
                {/*<Footer />*/}
            </div>
        );
    }
}
export default Contact;