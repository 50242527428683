import React, { Component } from 'react';
import { Trans } from "react-i18next";
import './Brochure.css';

class Brochure extends Component {
    render() {
        return (
    <section className="rh rh-brochure">
        <div className="container">
            <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  alert alert-info">
                            <div className="reservation-banner">
                                <Trans>reservation_banner_message</Trans><br/><br/>
                                <a href="https://reservations.hotelvillafontanainn.com/" className="btn btn-primary"><Trans>click_here_message</Trans></a>
                            </div>
                        </div>
                    </div>
                    {/*<div className="alert alert-primary new-year-promo-section" role="alert">*/}
                    {/*    <div className="new-year-promo-title">*/}
                    {/*        <Trans>new_year_promo_title</Trans>*/}
                    {/*    </div>*/}
                    {/*    <br/>*/}
                    {/*    <div className="new-year-promo-description">*/}
                    {/*        <Trans>new_year_promo_description</Trans>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="alert alert-info breakfast-info" role="alert">
                        <Trans>open_turism</Trans>
                        <div className="reservation-image">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608593889/Hotel%20Villafontana%20Inn/draft_2_n3msc3.png" className="d-block w-100" alt="Third slide"/>
                        </div>
                    </div>
                    <div className="rh-brochure-title">
                        <h2>Hotel Villa Fontana Inn</h2>
                        <p><Trans>description1</Trans></p>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="rh rh-feature-box vfh-inf-box">
                                <span>
                                    <img src="/static/media/logo.87b38358.png" alt="Hotel Villafontana inn" className="logo"/><Trans>brochure</Trans></span>
                    </div>
                </div>
                
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="rh rh-feature-box">
                        <div className="row">
                            <div className="col-lg-4">
                                <Trans>brochure1</Trans>
                            </div>
                            <div className="col-lg-4">
                                <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660326/Hotel%20Villafontana%20Inn/71705964-0293-4260-bc54-536ce08de5b6_cuverq.jpg" alt="hotel villa fontana inn" /><br/><br/>
                                <Trans>brochure2</Trans>
                            </div>
                            <div className="col-lg-4">
                                <Trans>brochure3</Trans>
                                <img src="https://res.cloudinary.com/xcytek/image/upload/v1608594018/Hotel%20Villafontana%20Inn/RochioliVineyard_dkfz7e.jpg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
    </section>
        );
}
}
export default Brochure;