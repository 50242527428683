import React, { Component } from 'react';
import { Trans } from "react-i18next";

class AboutPage extends Component {
    render() {
        return (
<section className="rh rh-100 rh-about">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="rh-section-title">
                        <h2><Trans>about</Trans></h2>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="rh-about-img">
                        <div className="rh-img">
                            <img src="https://res.cloudinary.com/xcytek/image/upload/v1608660310/Hotel%20Villafontana%20Inn/8772d152-b640-4f5e-bbc2-9a34ca61bdc8_xvzmmi.jpg" alt="2"/>
                            <a href="single-rooms.html"></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="rh-about-title">
                        <p><Trans>aboutText</Trans></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
        );
}
}
export default AboutPage;