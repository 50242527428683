import React, { Component } from 'react';
import { Trans } from "react-i18next";
import './Newsletter.css';

class Newsletter extends Component {
    render() {
        return (
               <section className="rh rh-newsletter">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 rh-n-30">
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <div className="rh-news-sign">
                        {/*<h5><Trans>connect with us</Trans></h5>*/}
                        {/*<p><Trans>social media</Trans></p>*/}
                    </div>
                    <ul className="news-social">
                        {/*<li><a href="https://www.facebook.com/HotelVillafontanaInn/"><i className="fab fa-facebook" aria-hidden="true"></i></a></li>*/}
                        {/*<li><a href="javascript:;"><i className="fab fa-instagram" aria-hidden="true"></i></a></li>*/}
                    </ul>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 rh-n-30">
                </div>
            </div>
        </div>
    </section>
        );
}
}
export default Newsletter;