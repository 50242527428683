import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Trans } from "react-i18next";
import './Carousel.css';

class CarouselSlide extends Component {
    render() {
        return (
    <Carousel id="myCarousel" className="carousel slide" data-ride="carousel">
            <Carousel.Item>
                <img src="https://res.cloudinary.com/xcytek/image/upload/v1608588229/Hotel%20Villafontana%20Inn/c1-clean_prksb6.jpg" className="d-block w-100" alt="first slide"/>
                <Carousel.Caption>
                <div className="rh rh-banner-widgets">
                    <h1><Trans>caption1</Trans></h1>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src="https://res.cloudinary.com/xcytek/image/upload/v1608588229/Hotel%20Villafontana%20Inn/c2-clean_cwjojz.jpg" className="d-block w-100" alt="Third slide"/>
                <Carousel.Caption>
                <div className="rh rh-banner-widgets">
                    <h1><Trans>caption2</Trans></h1>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src="https://res.cloudinary.com/xcytek/image/upload/v1608588229/Hotel%20Villafontana%20Inn/c3-clean_2_jbfv4h.jpg" className="d-block w-100" alt="Third slide"/>
                <Carousel.Caption>
                <div className="rh rh-banner-widgets">
                        <h1><Trans>caption3</Trans></h1>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
    </Carousel>
);
}
}
export default CarouselSlide;