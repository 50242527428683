import React, { Component } from 'react';
import Header from '../Components/Header.jsx';
import RoomsAvailable from '../Components/RoomsAvailable.jsx';
import Newsletter from '../Components/Newsletter.jsx';
import Footer from '../Components/Footer.jsx';
import { Helmet } from "react-helmet";

class Contact extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Hotel, Ensenada, hotel en ensenada, ensenada hoteles, ensenada hotel, ensenada hoteles, stay in ensenada, rooms, booking, facility, , air condition, heating, ESPN, HBO, all USA Major Channels,
                    Room Service, Side walk cafe, Telephone in Rooms, Swimming Pool, Jacuzzi, Convenience Store, Boutiques, Panoramic Balconies,  Jr. Suites with large roman tubs, Private Parkings, Security 24 hours, Taxi lane, Free Continental Breakfast, Close to all Major Ensenada Attractions,
                    Turist Information and tours, Free WIFI " />
                    <link rel="canonical" href="http://hotelvillafontanainn.com" />
                    <title>Hotel Villa Fontana Inn</title>
                </Helmet>
                <Header />
                <RoomsAvailable />
                <Newsletter />
                {/*<Footer />*/}
            </div>
        );
    }
}
export default Contact;