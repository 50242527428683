import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import i18n from "i18next";
import { Helmet } from "react-helmet";
import 'react-image-lightbox/style.css';
import './css/bootstrap.min.css';
import './css/color.css';
import './css/style.css';

import Home from './Pages/Home.jsx';
import Rooms from './Pages/Rooms.jsx';
import Comments from './Pages/Comments.jsx';
import Places from './Pages/Places.jsx';
import Contact from './Pages/Contact.jsx';
import Gallery from './Pages/GalleryPage.jsx';

class App extends Component {

    state = {
        value: "es"
    };
    handleChange = event => {
        let newlang = event.target.value;
        this.setState(prevState => ({ value: newlang }));
        i18n.changeLanguage(newlang);
    };

    render() {

        return (
            <div className="App-Root">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="Hotel, Ensenada, hotel en ensenada, ensenada hoteles, ensenada hotel, ensenada hoteles, stay in ensenada" />
                    {/* <meta name="keywords" content="hotel, mexico, " /> */}
                    <link rel="canonical" href="http://hotelvillafontanainn.com" />
                    <title>Hotel Villa Fontana Inn</title>
                </Helmet>
                <div className="rh-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-4">
                                <ul className="top">
                                    <li>
                                        <select className="selectpicker" value={this.state.value} onChange={this.handleChange}>
                                            <option value="en">English</option>
                                            <option value="es">Spanish</option>
                                        </select>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-8 d-none">
                                <ul className="pull-right">
                                    <li>
                                        <a href="Javascript:;" data-toggle="modal" data-target=".rh-login">Login</a>
                                    </li>
                                    <li>
                                        <a href="Javascript:;" data-toggle="modal" data-target=".rh-signup">Signup</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/index/" component={Home} />
                    <Route path="/Rooms/" component={Rooms} />
                    <Route path="/Comments/" component={Comments} />
                    <Route path="/Places/" component={Places} />
                    <Route path="/Contact/" component={Contact} />
                    <Route path="/Gallery/" component={Gallery} />
                    <Redirect to="404" />
                </Switch>
            </div>
        );
    }
}

export default App;